import Carousel from '../../Carousel';
import { GamesSectionProps } from './props';
import GameCard from '@/components/core/Games/GameCard/Versions/Primary';
import { ErrorCard } from '@starsoft/common/components';
import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';

export default function GamesBaseSection({
  data,
  error,
  icon,
  isLoading,
  label,
  link,
  refetch,
  secondaryStyles,
  useAutoScroll = false,
}: GamesSectionProps) {
  const { t } = useTranslation('common');

  const Cards = useMemo(
    () =>
      data?.map(game => (
        <GameCard
          key={`slots_item_${String(link)}_${game?.slug}`}
          game={game}
        />
      )),
    [data, link],
  );

  const Skeletons = useMemo(
    () =>
      Array.from({ length: 20 }).map((_, i) => (
        <GameCard key={`slots_item_${String(link)}_skeleton_${i}`} isLoading />
      )),
    [link],
  );

  if (error) {
    return <ErrorCard error={error} refetch={refetch} />;
  }

  return (
    <Carousel
      icon={icon}
      label={t(label, {
        app_provider: process.env.NEXT_PUBLIC_BET_NAME,
      })}
      redirect={link}
      secondaryStyles={secondaryStyles}
      useAutoScroll={useAutoScroll}
      key={`carousel-section-${link}`}
    >
      {isLoading ? Skeletons : Cards}
    </Carousel>
  );
}

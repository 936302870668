import { useGamesRecentlyPlayed } from '@/api/games';
import Carousel from '@/components/Home/Carousel';
import { useMemo } from 'react';
import { MostPaidCarouselProps } from './props';
import { ErrorCard } from '@starsoft/common/components';
import GameCard from '@/components/core/Games/GameCard/Versions/Primary';
import { useUserAccount } from '@/api/user';
import { useTranslation } from '@starsoft/common/hooks';
import RecentlyPlayedShortcut from './Shortcut';

export default function RecentlyPlayedCarousel({
  isSkeleton,
}: MostPaidCarouselProps) {
  const { t } = useTranslation('common');
  const { isAuthorized } = useUserAccount();
  const { games, isLoading, refetch, error } = useGamesRecentlyPlayed({
    refetchOnMount: 'always',
  });

  const Games = useMemo(
    () =>
      games?.map(game => (
        <GameCard key={`home-recently-played-card-${game?.id}`} game={game} />
      )),
    [games],
  );

  const Skeletons = useMemo(
    () =>
      Array.from({ length: 20 }).map((_, idx) => (
        <GameCard key={`home-recently-played-card-${idx}-skeleton`} isLoading />
      )),
    [],
  );

  if (error) {
    return <ErrorCard error={error} refetch={refetch} />;
  }

  if (!isAuthorized || (!isLoading && games?.length == 0)) {
    return null;
  }

  return (
    <>
      <RecentlyPlayedShortcut />
      <Carousel
        label={t('recently_played')}
        icon="fa-duotone fa-rotate-left"
        size={10}
      >
        {isLoading || isSkeleton ? Skeletons : Games}
      </Carousel>
    </>
  );
}

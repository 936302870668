import { Image } from '@starsoft/common/components';
import styles from './styles.module.scss';
import { useGamesRecentlyPlayed } from '@/api/games';
import { useGameCard } from '@/hooks/useGameCard';
import { Device } from '@/enums/device';
import useTranslation from 'next-translate/useTranslation';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { fadeInRight } from '@/components/Bonus/BonusChip/variants/fadeInRight';

export default function RecentlyPlayedShortcut() {
  const { t } = useTranslation('common');
  const { push } = useRouter();
  const {
    isLoading,
    error,
    mostRecentGame,
    hideRecentGame,
    toggleHideRecentGame,
  } = useGamesRecentlyPlayed();
  const { imageMapping, redirectUrl, onHover } = useGameCard(mostRecentGame);

  const hideShortcut = isLoading || error || !mostRecentGame || hideRecentGame;

  function handleClose(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    toggleHideRecentGame();
  }

  function handleNavigate() {
    push(redirectUrl);
  }

  return (
    <AnimatePresence mode="wait">
      {!hideShortcut && (
        <motion.div
          onMouseEnter={onHover}
          onClick={handleNavigate}
          variants={fadeInRight}
          initial="hidden"
          animate="show"
          exit="hidden"
          className={styles.container}
        >
          <Image
            src={imageMapping.get(Device.Tablet)}
            width={100}
            height={48}
            className={styles.container__image}
            alt={`image-banner-${mostRecentGame?.name}`}
          />
          <div className={styles.container__row}>
            <div className={styles.container__column}>
              <p className={styles.container__column__description}>
                {t('last_game_shortcut_title')}
              </p>
              <p className={styles.container__column__title}>
                {mostRecentGame?.name}
              </p>
            </div>
            <i
              onClick={handleClose}
              className={`${styles.container__column__icon} fa-solid fa-times`}
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
